import React, {
  useMemo, useState, useEffect, useContext,
} from 'react';
import axios from 'axios';
import { useScreenClass } from 'react-grid-system';

import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ErrorMessage } from '@hookform/error-message';
import _theme from '../styles/theme';
import { Col, Row } from './StyledGridSystem';
import Card from './Card';
import FlexBox, { FlexBoxProps, FlexGrow } from './FlexBox';
import { RightAlign } from './UtilsComponents';
import Pill, { PillProps } from './Pill';
import Status from '../types/enum/Status';
import Button from './Button';
import Icon, { ClickableIcon } from './Icon';
import Table from './Table';
import NullStateTR from './NullStateTr';
import ALink from './ALink';
import CircleImage, { CircleImageSize } from './CircleImage';
import Input from './Input';
import { StudentContext } from '../context/StudentBehalfContext';
import { UserContext } from '../context/UserContext';
import ConfirmGradModal from './ConfirmGradModal';
import DefaultProfile from '../default_profile.png';

interface ScreenProps {
  readonly isMobile: boolean;
}
const StyledProfileTag = styled.a`
  display: inline;
  align-items: center;
  vertical-align: middle;
  // TODO: fix vertical alignment
  /* line-height: 20px; */
  color: ${({ color, theme }) => color || theme.color.textColor};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
const StyledPill = styled(Pill) <
  { isMobile?: boolean } & React.HTMLProps<PillProps>
>`
  background-color: #aaaaaa;
  color: #ffffff;
  margin: 0px 16px;
  height: 16px;
  align-self: center;
  padding: ${({ isMobile }) => (isMobile ? '9px 12px' : '1px 6px')};
`;
const StyledRow = styled(Row) <ScreenProps>`
  flex-direction: ${(props) => (props.isMobile ? 'column-reverse' : '')};
`;
const StyledFlexBox = styled(FlexBox) <
  { isMobile?: boolean } & React.HTMLProps<FlexBoxProps>
>`
  align-items: center;
  margin: 0px 16px;
`;

const StyledTR = styled.tr<ScreenProps>`
  display: ${(props) => (props.isMobile ? 'inline-grid' : 'table-row')};
  width: 100%;
`;
const StyledTH = styled.th<ScreenProps>`
  padding: ${(props) => (props.isMobile ? '8px 16px 0px 16px !important' : '8px 16px !important')};
`;
const StyledTD = styled.td<ScreenProps>`
  padding: ${(props) => (props.isMobile ? '0px 16px 8px 16px !important' : '8px 16px !important')};
`;
type ViewMode = 'view' | 'edit';
type UserType = 'admin' | 'student';
/* eslint-disable camelcase */
interface User {
  student_id: string;
  user_id: string;
  last_name: string;
  middle_name: string;
  first_name: string;
  canvas_id: string;
  cmkl_email: string;
  expected_graduation_date: string;
  diploma_name: string;
  profile_url: string;
}
type BasicUserInfoForm = {
  last_name: string;
  middle_name: string;
  first_name: string;
  cmkl_email: string;
  canvas_id: string | undefined;
};
type BasicUserInfoProps = {
  defViewMode: ViewMode;
  userType: UserType;
  userInfo: User;
  reloadParent: any;
  information_confirm: boolean;
};
const BasicUserInfo = ({
  defViewMode,
  userType,
  userInfo,
  reloadParent,
  information_confirm,
}: BasicUserInfoProps) => {
  const validationSchema = Yup.object({
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
    middle_name: Yup.string(),
    cmkl_email: Yup.string()
      .required('CMKL email is required')
      .email('CMKL email is invalid'),
    canvas_id: Yup.string(),
  });
  const diplomaSchema = Yup.object({
    diploma_name: Yup.string().required('Diploma name is required'),
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<BasicUserInfoForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      last_name: userInfo.last_name,
      middle_name: userInfo.middle_name,
      first_name: userInfo.first_name,
      cmkl_email: userInfo.cmkl_email,
      canvas_id: userInfo.canvas_id,
    },
  });
  const {
    register: diplomaRegister,
    handleSubmit: handleDiplomaSubmit,
    setValue: setValueDiploma,
    formState: { errors: diplomaErrors },
  } = useForm({
    resolver: yupResolver(diplomaSchema),
    mode: 'onBlur',
  });
  const currentUser = useContext(UserContext).user;
  const { isBehalf } = useContext(StudentContext);
  const screenClass = useScreenClass();
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  // Loading text on "Enrolled Courses"
  const [loading, setLoading] = useState<boolean>(true);
  const [viewMode, setViewMode] = useState<ViewMode>(defViewMode);
  const [diplomaViewMode, setDiplomaViewMode] = useState<ViewMode>('view');
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [diplomaName, setDiplomaName] = useState();
  const [verified, setVerified] = useState<boolean>(false);
  // Enrolled Course Information for CourseInfoModal
  const [basicUserInfo, setBasicUserInfo] = useState({
    user_id: '',
    last_name: '',
    middle_name: '',
    first_name: '',
    canvas_id: '',
    cmkl_email: '',
    profile_url: '',
  });
  const monthDiff = (date1: any, date2: any) => {
    let months;
    months = (date2.getFullYear() - date1.getFullYear()) * 12;
    months -= date1.getMonth();
    months += date2.getMonth();
    return months >= 0 && months <= 3;
  };
  const verify = (submitData: any) => {
    setDiplomaName(submitData.diploma_name);
    setIsConfirmModalOpen(true);
  };
  function checkEditing() {
    if (userType === 'admin') {
      return false;
    }
    if (userType === 'student' && !verified) {
      return false;
    }
    if (userType === 'student' && verified) {
      return true;
    }
    return false;
  }
  function concat(first: string, middle: string, last: string) {
    if (middle !== '') {
      return `${first} ${middle} ${last}`;
    }
    return `${first} ${last}`;
  }
  const confirmGraduation = (submitData: any) => {
    setLoading(true);
    axios
      .put(`/api/v1/students/${userInfo.student_id}/confirm`, {
        diploma_name: submitData,
      })
      .then(() => {
        setIsConfirmModalOpen(false);
        const path = '/personalInfo';
        // history.push(path);
        history.push({
          pathname: path,
          state: {
            userId: basicUserInfo.user_id,
            role:
              currentUser.role && currentUser.role !== ''
                ? currentUser.role
                : 'student',
          },
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  const onSubmitClicked = (data: any) => {
    if (
      isDirty
      && userType === 'admin'
      && userInfo.user_id
      && userInfo.user_id !== ''
    ) {
      axios
        .put(`/api/v1/users/${userInfo.user_id}/basic-information`, {
          first_name: data.first_name,
          middle_name: data.middle_name,
          last_name: data.last_name,
          cmkl_email: data.cmkl_email,
          canvas_id: data.canvas_id,
        })
        .then(() => {
          history.replace({
            pathname: '/personalInfo',
            state: { userId: userInfo.user_id, role: userType },
          });
          reloadParent();
          setViewMode('view');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const onError = (errors: any, e: any) => console.log('error', errors, e);
  const handleChange = (event: any) => {
    setBasicUserInfo({
      ...basicUserInfo,
      profile_url: URL.createObjectURL(event.target.files[0]),
    });
    const formData = new FormData();
    formData.append('file', event.target.files[0]);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    axios
      .put(`/api/v1/users/${userInfo.user_id}/profile`, formData, config)
      .then(() => {
        history.replace({
          pathname: '/personalInfo',
          state: { userId: userInfo.user_id, role: userType },
        });
        reloadParent();
        setViewMode('view');
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const hiddenFileInput = React.useRef<any>(null);
  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  const removeProfile = () => {
    axios
      .delete(`/api/v1/users/${userInfo.user_id}/profile`)
      .then(() => {
        reloadParent();
        setViewMode('view');
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    setBasicUserInfo({
      user_id: userInfo.user_id,
      last_name: userInfo.last_name,
      middle_name: userInfo.middle_name,
      first_name: userInfo.first_name,
      canvas_id: userInfo.canvas_id,
      cmkl_email: userInfo.cmkl_email,
      profile_url: userInfo.profile_url,
    });
    if (information_confirm) {
      setValueDiploma('diploma_name', userInfo.diploma_name);
    } else {
      setValueDiploma(
        'diploma_name',
        concat(userInfo.first_name, userInfo.middle_name, userInfo.last_name),
      );
    }

    setLoading(false);
  }, [userInfo]);
  useEffect(() => {
    setVerified(information_confirm);
  }, [information_confirm]);

  const history = useHistory();

  return (
    <>
      <ConfirmGradModal
        isOpen={isConfirmModalOpen}
        setOpen={setIsConfirmModalOpen}
        diplomaName={diplomaName}
        confirmGraduation={confirmGraduation}
      />
      <Card style={{ margin: '24px 0px' }}>
        <form key={1} onSubmit={handleSubmit(onSubmitClicked, onError)}>
          <FlexBox
            alignItems="center"
            style={{
              marginBottom: _theme.spacing.x2,
            }}>
            <FlexGrow>
              <h3>Basic Information</h3>
            </FlexGrow>
            <FlexGrow ml-auto style={{ marginLeft: 'auto' }}>
              <RightAlign>
                {viewMode === 'view' && (
                  <>
                    {(isBehalf || userType === 'student') && (
                      <StyledPill isMobile={isMobile}>
                        Enrolled Student
                      </StyledPill>
                    )}
                    <Button
                      onClick={() => {
                        setViewMode('edit');
                      }}
                      disabled={!!(!isBehalf && userType === 'student')}
                      isOutline
                      status={Status.primary}
                      style={{ padding: '4px 12px' }}>
                      <Icon name="pencil-edit" mr={0.5} />
                      Edit
                    </Button>
                  </>
                )}
                {viewMode === 'edit' && !isMobile && (
                  <>
                    <Button
                      onClick={() => {
                        setViewMode('view');
                      }}
                      isOutline
                      status={Status.primary}
                      style={{ padding: '4px 12px' }}>
                      <Icon name="x-s" mr={0.5} />
                      Discard Changes
                    </Button>
                    <Button
                      type="submit"
                      onClick={() => handleSubmit(onSubmitClicked)}
                      status={Status.primary}
                      backgroundColor="#CB092B"
                      style={{ padding: '4px 12px', margin: '0px 16px' }}>
                      <Icon name="check-mark" mr={0.5} />
                      Save Changes
                    </Button>
                  </>
                )}
              </RightAlign>
            </FlexGrow>
          </FlexBox>
          <StyledRow isMobile={isMobile} style={{ marginBottom: 0 }}>
            <Col md={9}>
              <Table>
                <tbody>
                  {!loading ? (
                    <>
                      <StyledTR isMobile={isMobile}>
                        <StyledTH
                          isMobile={isMobile}
                          style={{
                            verticalAlign: 'top',
                            borderBottom: '0px',
                            whiteSpace: 'nowrap',
                            padding: '8px 16px',
                            width: '30%',
                          }}>
                          First Name
                        </StyledTH>
                        <StyledTD
                          isMobile={isMobile}
                          style={{
                            borderBottom: '0px',
                            overflowWrap: 'anywhere',
                            padding: '8px 16px',
                          }}>
                          {viewMode === 'view' ? (
                            <>
                              {basicUserInfo.first_name !== ''
                                ? basicUserInfo.first_name
                                : '-'}
                            </>
                          ) : (
                            <>
                              <Input
                                isFullWidth
                                type="text"
                                {...register('first_name')}
                                className={`form-control ${errors.first_name ? 'is-invalid' : ''}`}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="first_name"
                                render={({ message }) => (
                                  <p
                                    style={{
                                      marginTop: 0,
                                      color: _theme.color.danger,
                                    }}>
                                    {message}
                                  </p>
                                )}
                              />
                            </>
                          )}
                        </StyledTD>
                      </StyledTR>
                      <StyledTR isMobile={isMobile}>
                        <StyledTH
                          isMobile={isMobile}
                          style={{
                            verticalAlign: 'top',
                            borderBottom: '0px',
                            whiteSpace: 'nowrap',
                            padding: '8px 16px',
                            width: '30%',
                          }}>
                          Middle Name
                        </StyledTH>
                        <StyledTD
                          isMobile={isMobile}
                          style={{
                            borderBottom: '0px',
                            overflowWrap: 'anywhere',
                            padding: '8px 16px',
                          }}>
                          {viewMode === 'view' ? (
                            <>
                              {basicUserInfo.middle_name !== ''
                                ? basicUserInfo.middle_name
                                : '-'}
                            </>
                          ) : (
                            <>
                              <Input
                                isFullWidth
                                type="text"
                                {...register('middle_name')}
                                className={`form-control ${errors.middle_name ? 'is-invalid' : ''}`}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="middle_name"
                                render={({ message }) => (
                                  <p
                                    style={{
                                      marginTop: 0,
                                      color: _theme.color.danger,
                                    }}>
                                    {message}
                                  </p>
                                )}
                              />
                            </>
                          )}
                        </StyledTD>
                      </StyledTR>
                      <StyledTR isMobile={isMobile}>
                        <StyledTH
                          isMobile={isMobile}
                          style={{
                            verticalAlign: 'top',
                            borderBottom: '0px',
                            whiteSpace: 'nowrap',
                            padding: '8px 16px',
                            width: '30%',
                          }}>
                          Last Name
                        </StyledTH>
                        <StyledTD
                          isMobile={isMobile}
                          style={{
                            borderBottom: '0px',
                            overflowWrap: 'anywhere',
                            padding: '8px 16px',
                          }}>
                          {viewMode === 'view' ? (
                            <>
                              {basicUserInfo.last_name !== ''
                                ? basicUserInfo.last_name
                                : '-'}
                            </>
                          ) : (
                            <>
                              <Input
                                isFullWidth
                                type="text"
                                {...register('last_name')}
                                className={`form-control ${errors.last_name ? 'is-invalid' : ''}`}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="last_name"
                                render={({ message }) => (
                                  <p
                                    style={{
                                      marginTop: 0,
                                      color: _theme.color.danger,
                                    }}>
                                    {message}
                                  </p>
                                )}
                              />
                            </>
                          )}
                        </StyledTD>
                      </StyledTR>
                      <StyledTR isMobile={isMobile}>
                        <StyledTH
                          isMobile={isMobile}
                          style={{
                            verticalAlign: 'top',
                            borderBottom: '0px',
                            whiteSpace: 'nowrap',
                            padding: '8px 16px',
                            width: '30%',
                          }}>
                          CMKL Email Address
                        </StyledTH>
                        <StyledTD
                          isMobile={isMobile}
                          style={{
                            borderBottom: '0px',
                            overflowWrap: 'anywhere',
                            padding: '8px 16px',
                          }}>
                          {viewMode === 'view' ? (
                            <>
                              {basicUserInfo.cmkl_email !== ''
                                ? basicUserInfo.cmkl_email
                                : '-'}
                            </>
                          ) : (
                            <>
                              <Input
                                isFullWidth
                                type="text"
                                {...register('cmkl_email')}
                                className={`form-control ${errors.cmkl_email ? 'is-invalid' : ''}`}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="cmkl_email"
                                render={({ message }) => (
                                  <p
                                    style={{
                                      marginTop: 0,
                                      color: _theme.color.danger,
                                    }}>
                                    {message}
                                  </p>
                                )}
                              />
                            </>
                          )}
                        </StyledTD>
                      </StyledTR>
                      <StyledTR isMobile={isMobile}>
                        <StyledTH
                          isMobile={isMobile}
                          style={{
                            borderBottom: '0px',
                            whiteSpace: 'nowrap',
                            padding: '8px 16px',
                            verticalAlign: 'top',
                          }}>
                          Canvas Profile
                        </StyledTH>
                        <StyledTD
                          isMobile={isMobile}
                          style={{
                            borderBottom: '0px',
                            overflowWrap: 'anywhere',
                            padding: '8px 16px',
                          }}>
                          {viewMode === 'view' ? (
                            <>
                              {basicUserInfo.canvas_id !== '' ? (
                                <ALink href={`${basicUserInfo.canvas_id}`}>
                                  {basicUserInfo.canvas_id}
                                </ALink>
                              ) : (
                                '-'
                              )}
                            </>
                          ) : (
                            <>
                              <Input
                                isFullWidth
                                type="text"
                                {...register('canvas_id')}
                                className={`form-control ${errors.canvas_id ? 'is-invalid' : ''}`}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="canvas_id"
                                render={({ message }) => (
                                  <p
                                    style={{
                                      marginTop: 0,
                                      color: _theme.color.danger,
                                    }}>
                                    {message}
                                  </p>
                                )}
                              />
                            </>
                          )}
                        </StyledTD>
                      </StyledTR>
                    </>
                  ) : (
                    <NullStateTR>
                      <Icon mr={1} name="spinner" spin />
                      Loading...
                    </NullStateTR>
                  )}
                </tbody>
              </Table>
            </Col>
            <Col md={3} style={{ alignSelf: 'flex-start' }}>
              <StyledFlexBox isMobile={isMobile} direction="column">
                <StyledProfileTag
                  style={{ margin: '10px 0px', position: 'relative' }}
                  href={`${basicUserInfo.profile_url}`}>
                  <CircleImage
                    src={basicUserInfo.profile_url || DefaultProfile}
                    style={{
                      width: '128px',
                      height: '128px',
                      border: '1px #5f5f5f solid',
                    }}
                  />
                  <ClickableIcon style={{
                    background: '#d7d7d7',
                    color: 'red',
                    borderRadius: '50%',
                    position: 'absolute',
                    right: '17px',
                    top: '3px',
                    padding: '4px 0',
                  }} name={'x-s'} onClick={() => removeProfile()} />
                </StyledProfileTag>
                <Button
                  isOutline
                  status={Status.primary}
                  onClick={handleClick}
                  style={{ padding: '4px 12px' }}>
                  <Icon name="pencil-edit" />
                  Change Photo
                </Button>
                <input
                  type="file"
                  ref={hiddenFileInput}
                  accept="image/*"
                  onChange={handleChange}
                  style={{ display: 'none' }}
                />
              </StyledFlexBox>
            </Col>
          </StyledRow>
          {viewMode === 'edit' && isMobile && (
            <FlexBox
              direction={'row'}
              justify="space-between"
              alignItems="center">
              <FlexGrow>
                <Button
                  onClick={() => {
                    setViewMode('view');
                  }}
                  isOutline
                  status={Status.primary}
                  style={{ padding: '0px 5px', border: 'none' }}>
                  <Icon name="x-s" mr={0.5} />
                  Cancel
                </Button>
              </FlexGrow>
              <FlexGrow>
                <RightAlign>
                  <Button
                    type="submit"
                    onClick={() => handleSubmit(onSubmitClicked)}
                    status={Status.primary}
                    backgroundColor="#CB092B"
                    style={{ padding: '0px 10px' }}>
                    <Icon name="check-mark" mr={0.5} />
                    Save
                  </Button>
                </RightAlign>
              </FlexGrow>
            </FlexBox>
          )}
        </form>
        {((currentUser.role === 'admin' && isBehalf)
          || currentUser.role === 'student')
          && monthDiff(
            new Date(),
            new Date(userInfo.expected_graduation_date),
          ) && (
            <form key={2} onSubmit={handleDiplomaSubmit(verify, onError)}>
              <StyledRow isMobile={isMobile}>
                <Col md={9}>
                  <Table>
                    <tbody>
                      {!loading ? (
                        <>
                          <StyledTR isMobile={isMobile}>
                            <StyledTH
                              isMobile={isMobile}
                              style={{
                                verticalAlign: 'top',
                                borderBottom: '0px',
                                whiteSpace: 'nowrap',
                                padding: '8px 16px',
                                width: '30%',
                              }}>
                              Diploma Name
                            </StyledTH>
                            <StyledTD
                              isMobile={isMobile}
                              style={{
                                borderBottom: '0px',
                                overflowWrap: 'anywhere',
                                padding: '8px 16px',
                              }}>
                              {diplomaViewMode === 'view' ? (
                                <>
                                  {userInfo.diploma_name !== '' ? (
                                    <>{userInfo.diploma_name}</>
                                  ) : (
                                    '-'
                                  )}
                                  {!checkEditing() && (
                                    <ClickableIcon
                                      onClick={() => {
                                        setDiplomaViewMode('edit');
                                      }}
                                      disabled={checkEditing()}
                                      name="pencil-edit"
                                      ml={2}
                                      style={{
                                        color: '#CB092B',
                                      }}></ClickableIcon>
                                  )}
                                </>
                              ) : (
                                <FlexBox
                                  direction={'column'}
                                  alignItems="flex-start">
                                  <FlexBox style={{ width: '100%' }}>
                                    <FlexGrow grow={2}>
                                      <Input
                                        isFullWidth
                                        type="text"
                                        {...diplomaRegister('diploma_name')}
                                        className={`form-control ${diplomaErrors.diploma_name ? 'is-invalid' : ''}`}
                                      />
                                      <ErrorMessage
                                        errors={diplomaErrors}
                                        name="diploma_name"
                                        render={({ message }) => (
                                          <p
                                            style={{
                                              marginTop: 0,
                                              color: _theme.color.danger,
                                            }}>
                                            {message}
                                          </p>
                                        )}
                                      />
                                    </FlexGrow>
                                  </FlexBox>
                                </FlexBox>
                              )}
                            </StyledTD>
                          </StyledTR>
                        </>
                      ) : (
                        <NullStateTR>
                          <Icon mr={1} name="spinner" spin />
                          Loading...
                        </NullStateTR>
                      )}
                    </tbody>
                  </Table>
                </Col>
                <Col md={3} style={{ alignSelf: 'center' }}>
                  {diplomaViewMode === 'edit' && !isMobile && (
                    <FlexBox>
                      <Button
                        onClick={() => {
                          setDiplomaViewMode('view');
                        }}
                        isOutline
                        status={Status.primary}
                        style={{ padding: '0px 5px', border: 'none' }}>
                        <Icon name="x-s" mr={0.5} />
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        onClick={() => handleDiplomaSubmit(onSubmitClicked)}
                        status={Status.primary}
                        backgroundColor="#CB092B"
                        style={{ padding: '0px 12px' }}>
                        <Icon name="check-mark" mr={0.5} />
                        Verify
                      </Button>
                    </FlexBox>
                  )}
                </Col>
              </StyledRow>
            </form>
        )}
      </Card>
    </>
  );
};
export default BasicUserInfo;
