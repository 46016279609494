import {
  HTMLProps, useEffect, useMemo, useState,
} from 'react';
import styled from 'styled-components';

import axios from 'axios';
import { useScreenClass } from 'react-grid-system';
import { useHistory } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import Icon from '../../components/Icon';
import { Col, Row } from '../../components/StyledGridSystem';
import Status from '../../types/enum/Status';
import FlexBox, { FlexGrow } from '../../components/FlexBox';
import Card from '../../components/Card';
import Table from '../../components/Table';
import {
  NullStateText,
  StyledText,
  TextSize,
  TextStatus,
} from '../../components/Text';
import NullStateTR from '../../components/NullStateTr';
import theme from '../../styles/theme';
import Button from '../../components/Button';
import {
  CourseContent,
  EmptyCourseContent,
  FilterValue,
  OfferSortOption,
} from '../../filter/FilterValue';
import { RightAlign } from '../../components/UtilsComponents';
import Input, {
  InputContainer,
  InputIconContainer,
} from '../../components/Input';
import MinimalSelect from '../../components/MinimalSelect';
import useDebounce from '../../components/use-debounce';
import CourseInfoModal from '../../components/CourseInfoModal';
import EditSectionModal from '../../components/EditSectionModal';
import CourseOfferActiveModal from '../../components/CourseOfferActiveModal';
import CourseOfferDropdown from '../../components/CourseOfferDropdown';
import SnackBar from '../../components/SnackBar';

export interface Ioption {
  semester: any;
  program: any;
  time: any;
  day: any;
}
const FilterStyled = styled.div<
  { isMobile?: boolean } & HTMLProps<HTMLDivElement>
>`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.spacing.x2};
`;

const RowStyled = styled.div`
  display: flex;
  flex-flow: column;
`;
const MinimalSelectStyled = styled.div<
  { isMobile?: boolean } & HTMLProps<HTMLDivElement>
>`
  margin-right: ${({ isMobile, theme }) => (isMobile ? theme.spacing.x4 : theme.spacing.x3)};
  color: ${({ theme }) => theme.color.textColor};
  white-space: nowrap;
`;

function CourseOfferingsPage() {
  const screenClass = useScreenClass();
  const history = useHistory();
  const isMobile = useMemo(
    () => ['xs', 'sm'].includes(screenClass),
    [screenClass],
  );
  const test = [
    { name: 'Courses', link: '/admin/courses' },
    { name: 'Course Offerings', link: '/admin/courses/course/offerings' },
  ];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentCourse, setCurrentCourse] = useState<CourseContent>(EmptyCourseContent);
  const [posts, setPosts] = useState<any | null>();
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [isActiveOpen, setIsActiveOpen] = useState(false);
  const [isInactiveOpen, setIsInactiveOpen] = useState(false);
  const [isCreateSectionOpen, setIsCreateSectionOpen] = useState<boolean>(false);
  const ColumnName = (isMobile: boolean) => {
    if (isMobile) {
      return (
        <>
          <th></th>
          <th></th>
        </>
      );
    }
    return (
      <>
        <th style={{ width: '25%' }}>Course Code</th>
        <th style={{ width: '45%' }}>Course Name</th>
        <th style={{ width: '15%' }}>Units</th>
        <th style={{ width: '15%' }}>Programs</th>
        <th style={{ width: '15%' }}></th>
      </>
    );
  };
  const [filters, setfilters] = useState({
    semester: '',
    program: [],
    time: [],
    day: [],
    sort: 'Term Start DESC',
    status: '',
  });
  const debouncedSearchTerm = useDebounce(searchValue, 300);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [options, setOptions] = useState({
    semester: [],
    program: [{ label: 'All', value: 'All' }],
    time: [],
    day: [],
    status: [
      { label: 'active', value: 'active' },
      { label: 'inactive', value: 'inactive' },
    ],
  });
  useEffect(() => {
    fetchOptions();
  }, []);
  useEffect(() => {
    if (debouncedSearchTerm) {
      fetchPostbyPage();
    } else {
      fetchPostbyPage();
    }
  }, [filters, debouncedSearchTerm]);
  const fetchPostbyPage = () => {
    setLoading(true);
    axios
      .get(
        `/api/v1/courses/course-offerings?term=${filters.semester}&query=${searchValue}&status=${filters.status}&sort=${filters.sort}`,
      )
      .then((res) => {
        setPosts(mapCourseByTerm(res.data.data.courses));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setPosts(null);
        setLoading(false);
      });
  };
  const fetchOptions = () => {
    setLoading(true);
    const termsVals: any = [{ label: 'All', value: '' }];
    axios
      .get('/api/v1/courses/terms')
      .then((res) => {
        if (res.data.data.terms) {
          res.data.data.terms.forEach((term: any) => {
            termsVals.push({ label: term.name, value: term.name });
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    setOptions({ ...options, semester: termsVals });
  };
  const handleChangeSort = (obj: any) => {
    setfilters({ ...filters, sort: obj.value });
  };
  const mapCourseByTerm = (courses: Array<CourseContent>) => {
    let groupByCategory: any = {};
    if (courses.length === 0 && filters.semester !== '') {
      groupByCategory[filters.semester] = [];
    } else {
      groupByCategory = courses.reduce((group: any, product) => {
        // eslint-disable-next-line no-param-reassign
  
        group[product.term] = group[product.term] ?? [];
        group[product.term].push(product);
        return group;
      }, {});
    }
    return groupByCategory;
  };
  const handleSearchChange = (text: string) => {
    setSearchValue(text);
  };
  const handleChangeProgram = (obj: any) => {
    if (obj) {
      setfilters({ ...filters, semester: obj.value });
    } else {
      setfilters({ ...filters, semester: '' });
    }
  };
  const handleChangeStatus = (obj: any) => {
    if (obj) {
      setfilters({ ...filters, status: obj.value });
    } else {
      setfilters({ ...filters, status: '' });
    }
  };
  const addCourse = (semesterName: string) => {
    const path = `/admin/course/offerings/add?semester=${semesterName}`;
    history.push(path);
  };
  const openModal = (data: any) => {
    if (data) {
      setCurrentCourse(data);
      setIsModalOpen(true);
    }
  };
  const openActiveModal = (data: any) => {
    if (data) {
      setCurrentCourse(data);
      setIsActiveOpen(true);
    }
  };

  // Drop Course Modal
  const openInactiveModal = (course: any, e: MouseEvent) => {
    setCurrentCourse(course);
    setIsInactiveOpen(true);
    e.stopPropagation();
  };
  const openAddSection = (data: any) => {
    if (data) {
      setCurrentCourse(data);
      setIsCreateSectionOpen(true);
    }
  };
  const changePage = (sectionID: string) => {
    setTimeout(() => {
      const path = `/section/students?section=${sectionID}`;
      history.push(path);
    }, 400);
  };

   const sortCoursesByCode = (courses: any) => {
    return courses.slice().sort((a:any, b:any) => a.code.localeCompare(b.code));
  };

  // Sort the posts object by sorting each course array by code
  const sortedPosts = posts && Object.entries(posts).reduce(
    (acc, [key, courseArray]) => ({
      ...acc,
      [key]: sortCoursesByCode(courseArray)
    }),
    {}
  );
  
  return (
    <>
      <SnackBar
        isOpen={snackbarOpen}
        setOpen={setSnackbarOpen}
        type="success"
        message="Created section successfully!"
      />
      {isCreateSectionOpen && (
        <EditSectionModal
          isOpen={isCreateSectionOpen}
          setOpen={setIsCreateSectionOpen}
          courseId={currentCourse.course_offer_id}
          termId={currentCourse.term_obj.id}
          mode="create"
          onEditComplete={() => {
            setSnackbarOpen(true);
            fetchPostbyPage();
          }}
          onEditError={() => {}}
        />
      )}
      <CourseInfoModal
        isOpen={isModalOpen}
        setOpen={setIsModalOpen}
        redirectStudents={(id: string) => changePage(id)}
        currentCourse={currentCourse}
      />
      {isInactiveOpen && (
        <CourseOfferActiveModal
          isOpen={isInactiveOpen}
          setOpen={setIsInactiveOpen}
          currentCourse={currentCourse}
          // handleDeleteCourse={handleDeleteCourse}
          onDeleteComplete={() => {
            fetchPostbyPage();
          }}
          onDeleteError={() => {
            fetchPostbyPage();
          }}
          courseOfferStatus="inactive"
        />
      )}
      {isActiveOpen && (
        <CourseOfferActiveModal
          isOpen={isActiveOpen}
          setOpen={setIsActiveOpen}
          currentCourse={currentCourse}
          // handleDeleteCourse={handleDeleteCourse}
          onDeleteComplete={() => {
            fetchPostbyPage();
          }}
          onDeleteError={() => {
            fetchPostbyPage();
          }}
          courseOfferStatus="active"
        />
      )}
      <Row>
        <Col>
          <Breadcrumb path={test} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FlexBox space={2}>
            <h2 style={{ marginRight: 'auto', marginBottom: 0 }}>
              Course Offerings
            </h2>
          </FlexBox>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={{ marginTop: '0px' }}>
            <FlexBox
              style={{
                alignItems: 'flex-start',
                marginBottom: theme.spacing.base * 2,
              }}>
              <FlexGrow>
                <h3>Look Up Course Information</h3>
              </FlexGrow>
            </FlexBox>
            <InputContainer
              as="form"
              onSubmit={() => {}}
              style={{ width: '100%' }}>
              <Input
                id="search-input"
                name="searchInput"
                // eslint-disable-next-line max-len
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => handleSearchChange(e.target.value)
                }
                placeholder="Find courses..."
                value={searchValue}
                style={{ width: 'inherit' }}
              />
              <InputIconContainer onClick={(e: any) => e.preventDefault()}>
                <Icon name="search" status={Status.default} />
              </InputIconContainer>
            </InputContainer>
            <FilterStyled isMobile={isMobile}>
              <MinimalSelectStyled isMobile={isMobile}>
                <StyledText status={TextStatus.default}>Term:</StyledText>
                <MinimalSelect
                  placeholder="All"
                  options={options.semester}
                  onChange={handleChangeProgram}
                />
              </MinimalSelectStyled>
              <MinimalSelectStyled isMobile={isMobile}>
                <StyledText status={TextStatus.default}>Status:</StyledText>
                <MinimalSelect
                  placeholder="All"
                  options={options.status}
                  onChange={handleChangeStatus}
                />
              </MinimalSelectStyled>
              <MinimalSelectStyled isMobile={isMobile}>
                <StyledText status={TextStatus.default}>Sort:</StyledText>
                <MinimalSelect
                  options={OfferSortOption}
                  onChange={handleChangeSort}
                  value={OfferSortOption.find((v:FilterValue)=>v.value===filters.sort)}
                />
              </MinimalSelectStyled>
            </FilterStyled>
          </Card>
        </Col>
      </Row>
      {posts ? (
        Object.entries(sortedPosts).map(([keyName, courseArray]) => (
        
          <Row>
            <Col>
              <Card style={{ marginTop: '0px' }}>
                <FlexBox style={{ marginBottom: '16px' }}>
                  <FlexGrow>
                    <h3>{keyName}</h3>
                  </FlexGrow>
                  <Button
                    onClick={() => addCourse(keyName)}
                    isOutline
                    status={Status.primary}
                    style={{ padding: '4px 12px' }}>
                    <Icon name="plus-s" mr={0.5} />
                    Create Course Offer
                  </Button>
                </FlexBox>
                <Table hoverable>
                  <thead>
                    <tr>{ColumnName(isMobile)}</tr>
                  </thead>
                  <tbody>
                    {!loading && courseArray ? (
                      (courseArray as Array<any>).map((data) => {
                        if (!isMobile) {
                          return (
                            <tr key={data.id} onClick={() => openModal(data)}>
                              <td>
                                <StyledText
                                  textSize={TextSize.default}
                                  // status={TextStatus.disabled}
                                >
                                  {data.code}
                                </StyledText>
                              </td>
                              <td>{data.short_name}</td>
                              <td>{data.units}</td>
                              <td>
                                {data?.programs ? (
                                  data?.programs.map((program: any) => (
                                    <div>·{program.name}</div>
                                  ))
                                ) : (
                                  <>-</>
                                )}
                              </td>
                              <td
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                style={{ verticalAlign: 'middle' }}>
                                <CourseOfferDropdown
                                  course={data}
                                  openAddSection={openAddSection}
                                  openActiveModal={openActiveModal}
                                  openInactiveModal={openInactiveModal}
                                />
                              </td>
                            </tr>
                          );
                        }

                        return (
                          <tr key={data.id} onClick={() => openModal(data)}>
                            <td>
                              <RowStyled>
                                <StyledText style={{ fontWeight: 'bold' }}>
                                  {data.short_name}
                                </StyledText>
                                <StyledText status={TextStatus.muted}>
                                  {data.code}
                                </StyledText>
                                <StyledText status={TextStatus.muted}>
                                  {data.units} Units
                                </StyledText>
                                <StyledText status={TextStatus.muted}>
                                  {data.programs ? (
                                    data.programs.map((program: any) => (
                                      <>
                                        {program.name}
                                        {data.programs.length > 1 && '-'}
                                      </>
                                    ))
                                  ) : (
                                    <>-</>
                                  )}
                                </StyledText>
                              </RowStyled>
                            </td>
                            <td
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              style={{ verticalAlign: 'middle' }}>
                              <CourseOfferDropdown
                                course={data}
                                openAddSection={openAddSection}
                                openActiveModal={openActiveModal}
                                openInactiveModal={openInactiveModal}
                              />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <div>
                        <NullStateTR>No courses found.</NullStateTR>
                      </div>
                    )}
                  </tbody>
                </Table>
                {!loading ? (
                  <div></div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      padding: theme.spacing.x2,
                    }}>
                    <Icon
                      name="spinner"
                      spin
                      size={4}
                      color={theme.color.textDisabled}
                    />
                    {/* <CircularProgress disableShrink /> */}
                  </div>
                )}
              </Card>
            </Col>
          </Row>
        ))
      ) : (
        <>
          <Row>
            <Col>
              <Card style={{ marginTop: '0px' }}>
                <FlexBox style={{ marginBottom: '12px' }}>
                  <FlexGrow>
                    <NullStateText>No data found.</NullStateText>
                  </FlexGrow>
                </FlexBox>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
export default CourseOfferingsPage;
