/* eslint-disable max-len */
import React, {
  useEffect, useMemo, useState, useContext,
} from 'react';
import { Container, useScreenClass } from 'react-grid-system';
import styled, { css } from 'styled-components';
import axios from 'axios';
import { UserContext } from '../context/UserContext';

import Navbar from '../components/Navbar';
import BaseStyle from '../styles/BaseStyle';
import Sidebar from '../components/Sidebar';

import SidebarMenuItem from '../components/SidebarMenuItem';
import CircleImage, { CircleImageSize } from '../components/CircleImage';
import User from '../types/User';
import theme from '../styles/theme';
import Icon from '../components/Icon';
import Backdrop from '../components/Backdrop';
import { StudentContext } from '../context/StudentBehalfContext';

type PageAreaProps = {
  isSidebarOpen?: boolean;
  shouldShift?: boolean;
  isBehalfActive?: boolean;
} & React.HTMLProps<HTMLDivElement>;

const PageArea = styled.div<PageAreaProps>`
  padding-top: ${({ theme }) => theme.navbarHeight};
  padding-left: ${({ isSidebarOpen, shouldShift, theme }) => (isSidebarOpen && shouldShift ? theme.sidebarWidth : 0)};
  transition: all ${({ theme }) => theme.transitionTime.default};
  ${({ isBehalfActive }) => isBehalfActive
    && css`
      padding-top: ${({ theme }) => `calc(${theme.navbarHeight} + ${theme.spacing.x8})`};
    `}
`;

const Droplist = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  margin-bottom: 0px;
  text-overflow: clip;
  white-space: nowrap;
`;

const PicName = styled.div`
  display: flex;
  flex-flow: row;
`;

const UserStyled = styled.div`
  display: flex;
  flex-flow: column;
`;

const Name = styled.div`
  padding-left: ${({ theme }) => theme.spacing.xxs};
  font-size: 0.875rem;
`;

const EnrollStyled = styled.div`
  padding-top: ${({ theme }) => theme.spacing.xxs};
  color: ${({ theme }) => theme.color.textSecondary};
`;

export type Props = {
  children: React.ReactNode;
  user?: User;
};

function BasicLayout({ children, user }: Props): JSX.Element {
  const screenClass = useScreenClass();
  const currentUser = useContext(UserContext).user;
  const { isSignedIn } = useContext(UserContext);
  const { isBehalf, student } = useContext(StudentContext);
  const isMobile = useMemo(
    () => ['xs', 'sm', 'md'].includes(screenClass),
    [screenClass],
  );

  const shouldShowSidebarByDefault = useMemo(() => !isMobile, [isMobile]);
  const [isSidebarOpen, setSidebarOpen] = useState(shouldShowSidebarByDefault);
  const [isCourseOpen, setIsCourseOpen] = useState(false);
  const [isFinanceOpen, setIsFinanceOpen] = useState(false);
  const [isDocumentOpen, setIsDocumentOpen] = useState(false);

  const toggleCourseDropdown = () => {
    setIsCourseOpen((prevState) => !prevState);
    if (isDocumentOpen) {
      setIsDocumentOpen(false);
    }
    if (isFinanceOpen) {
      setIsFinanceOpen(false);
    }
  };
  const toggleFinanceDropdown = () => {
    setIsFinanceOpen((prevState) => !prevState);
    if (isDocumentOpen) {
      setIsDocumentOpen(false);
    }
    if (isCourseOpen) {
      setIsCourseOpen(false);
    }
  };

  const toggleDocumentDropdown = () => {
    setIsDocumentOpen((prevState) => !prevState);
    if (setIsCourseOpen) {
      setIsCourseOpen(false);
    }
  };

  // useEffect(() => () => {
  //   // Reset Sidebar when close
  //   if (isCourseOpen) {
  //     setIsCourseOpen(false);
  //   }
  //   if (isDocumentOpen) {
  //     setIsDocumentOpen(false);
  //   }
  // }, [isSidebarOpen]);

  useEffect(() => {
    setSidebarOpen(shouldShowSidebarByDefault);
  }, [shouldShowSidebarByDefault]);

  const TriangleStyled = {
    cursor: 'pointer',
    paddingTop: theme.spacing.xxs,
    display: 'transparent',
  };

  const SetSidebarClose = () => {
    if (isMobile) {
      setSidebarOpen(false);
    }
  };

  let curUser: JSX.Element = (
    <SidebarMenuItem
      name="Sign In"
      to="/signin"
      margin={1}
      icon="key"
      isMain
      fontSize="16px"
      click={SetSidebarClose}
    />
  );
  if (isSignedIn) {
    curUser = (
      <>
        <PicName>
          <CircleImage
            src={currentUser.picture}
            size={CircleImageSize.medium}
            style={{
              marginRight: theme.spacing.x1,
              paddingTop: '3px',
            }}></CircleImage>
          <UserStyled>
            <Name>{currentUser.name}</Name>
            <Name>{/* {currentUser.lastname} */}</Name>
            <EnrollStyled>
              {currentUser.role === 'admin' ? (
                <Name> Admin </Name>
              ) : (
                <Name> Enrolled Student </Name>
              )}
            </EnrollStyled>
          </UserStyled>
        </PicName>
        <SidebarMenuItem
          name="Home"
          to="/"
          margin={1}
          icon="home"
          isMain
          fontSize="16px"
          click={SetSidebarClose}
        />
        {(isBehalf || currentUser.role === 'admin') && (
          <>
            <SidebarMenuItem
              name="Students"
              to="/students"
              margin={1}
              icon="user"
              isMain
              fontSize="16px"
              click={SetSidebarClose}
            />
            <SidebarMenuItem
              name="Terms"
              to="/terms"
              margin={1}
              icon="calendar-s"
              isMain
              fontSize="16px"
              click={SetSidebarClose}
            />
            <SidebarMenuItem
              name="Programs"
              to="/programs"
              margin={1}
              icon="book"
              isMain
              fontSize="16px"
              click={SetSidebarClose}
            />
          </>
        )}
        {isBehalf || currentUser.role === 'student' ? (
          <>
            <div style={{ marginBottom: '0px' }} onClick={toggleCourseDropdown}>
              <SidebarMenuItem
                name="Courses"
                to="/courses"
                margin={1}
                icon="book"
                isMain
                fontSize="16px"
                isDropDown={true}>
                <Icon mr={0} name="chevron-bottom-s" style={TriangleStyled} />
              </SidebarMenuItem>
            </div>
            {isCourseOpen && (
              <Droplist>
                <SidebarMenuItem
                  name="My Courses"
                  to="/courses/myCourses"
                  margin={1}
                  icon="bookmark"
                  isMain={false}
                  fontSize="16px"
                  click={SetSidebarClose}
                />
                {/* <SidebarMenuItem name="Registration" to='/courses/registration' margin={1} icon='atom' isMain={false} fontSize='16px' click={SetSidebarClose} /> */}
                <SidebarMenuItem
                  name="Course Catalog"
                  to="/courses/courseCatalog"
                  margin={1}
                  icon="note-text"
                  isMain={false}
                  fontSize="16px"
                  click={SetSidebarClose}
                />
                <SidebarMenuItem
                  name="Enrollment History"
                  to="/courses/history"
                  margin={1}
                  icon="time-history"
                  isMain={false}
                  fontSize="16px"
                  click={SetSidebarClose}
                />
              </Droplist>
            )}
          </>
        ) : (
          <>
            <div style={{ marginBottom: '0px' }} onClick={toggleCourseDropdown}>
              <SidebarMenuItem
                name="Courses"
                to="/admin/courses"
                margin={1}
                icon="bookmark"
                isMain
                fontSize="16px"
                isDropDown={true}>
                <Icon mr={0} name="chevron-bottom-s" style={TriangleStyled} />
              </SidebarMenuItem>
            </div>
            {isCourseOpen && (
              <Droplist>
                <SidebarMenuItem
                  name="Course Master"
                  to="/admin/courses/course/master"
                  margin={1}
                  icon="note-text"
                  isMain={false}
                  fontSize="16px"
                  click={SetSidebarClose}
                />
                <SidebarMenuItem
                  name="Course Offerings"
                  to="/admin/courses/course/offerings"
                  margin={1}
                  icon="note-text"
                  isMain={false}
                  fontSize="16px"
                  click={SetSidebarClose}
                />
                {/* <SidebarMenuItem
                  name="Record course grades"
                  to="/admin/courses/gradelink"
                  margin={1}
                  icon="link"
                  isMain={false}
                  fontSize="16px"
                  click={SetSidebarClose}
                /> */}
              </Droplist>
            )}
          </>
        )}
        <SidebarMenuItem
          name="Forms & Documents"
          to="/documents"
          margin={1}
          icon="file-s"
          isMain
          fontSize="16px"
          click={SetSidebarClose}
        />
        {!isBehalf && currentUser.role === 'admin' && (
          <>
            <div
              style={{ marginBottom: '0px' }}
              onClick={toggleFinanceDropdown}>
              <SidebarMenuItem
                name="Finance"
                to="/admin/finance"
                margin={1}
                icon="creditcard-face"
                isMain
                fontSize="16px"
                isDropDown={true}>
                <Icon mr={0} name="chevron-bottom-s" style={TriangleStyled} />
              </SidebarMenuItem>
            </div>
            {isFinanceOpen && (
              <Droplist>
                <SidebarMenuItem
                  name="Invoice"
                  to="/admin/finance/invoice"
                  margin={1}
                  icon="note-text"
                  isMain={false}
                  fontSize="16px"
                  click={SetSidebarClose}
                />
                <SidebarMenuItem
                  name="Receipt"
                  to="/admin/finance/receipt"
                  margin={1}
                  icon="paperclip"
                  isMain={false}
                  fontSize="16px"
                  click={SetSidebarClose}
                />
                <SidebarMenuItem
                  name="Template"
                  to="/admin/finance/template"
                  margin={1}
                  icon="form"
                  isMain={false}
                  fontSize="16px"
                  click={SetSidebarClose}
                />
              </Droplist>
            )}
            <SidebarMenuItem
              name="Instructors"
              to="/instructors"
              margin={1}
              icon="user"
              isMain
              fontSize="16px"
              click={SetSidebarClose}
            />
          </>
        )}
        {(isBehalf || currentUser.role === 'student') && (
          <>
            {/* <SidebarMenuItem
              name="Finance"
              to="/finance"
              margin={1}
              icon="creditcard-face"
              isMain
              fontSize="16px"
              click={SetSidebarClose}
            /> */}
          </>
        )}

        <SidebarMenuItem
          name="Personal Info"
          to={{
            pathname: '/personalInfo',
            state: {
              userId: isBehalf ? student.user_id : currentUser.user_id,
              role:
                currentUser.role && currentUser.role !== ''
                  ? currentUser.role
                  : 'student',
            },
          }}
          margin={1}
          icon="user"
          isMain
          fontSize="16px"
          click={SetSidebarClose}
        />

          <SidebarMenuItem
              name="Reports & Progress"
              to={{
                  pathname: '/reports',
                  state: {
                      userId: isBehalf ? student.user_id : currentUser.user_id,
                      role:
                          currentUser.role && currentUser.role !== ''
                              ? currentUser.role
                              : 'student',
                  },
              }}
              margin={1}
              icon="user"
              isMain
              fontSize="16px"
              click={SetSidebarClose}
          />
        <SidebarMenuItem
          name="Sign out"
          to="/signout"
          margin={1}
          icon="leave"
          isMain
          fontSize="16px"
          click={SetSidebarClose}></SidebarMenuItem>
      </>
    );
  }
  return (
    <>
      <BaseStyle />
      {/* <ThemeProvider theme={theme}> */}
      <Backdrop
        show={isSidebarOpen && isMobile}
        clicked={() => setSidebarOpen(false)}
      />
      <Navbar
        onSidebarToggle={() => setSidebarOpen(!isSidebarOpen)}
        showSignout={!isSidebarOpen}
      />
      <Sidebar isOpen={isSidebarOpen} isBehalfActive={isBehalf}>
        {curUser}
      </Sidebar>
      <PageArea
        isSidebarOpen={isSidebarOpen}
        isBehalfActive={isBehalf}
        shouldShift={shouldShowSidebarByDefault}>
        <Container style={{ paddingTop: theme.spacing.x3 }}>
          {children}
        </Container>
      </PageArea>
      {/* </ThemeProvider> */}
    </>
  );
}

export default BasicLayout;
