/* eslint-disable max-len */
import styled from "styled-components";
import {useContext, useEffect, useState} from "react";
import { jwtVerify, SignJWT } from "jose";
import {StudentContext} from "../../context/StudentBehalfContext";
import {UserContext} from "../../context/UserContext";


var METABASE_SITE_URL = "https://a1ce-test.cmkl.ac.th/reporting";
var METABASE_SECRET_KEY =
  "b053a2d9b05858102aac5267e598532ca1bc7d8e4be4882440c8ad7cfb277807";

// Create a JWT
async function createJWT(payload: any): Promise<string> {
  const secret = new TextEncoder().encode(METABASE_SECRET_KEY); // Must be Uint8Array
  const token = await new SignJWT(payload)
    .setProtectedHeader({ alg: "HS256" })
    .setIssuedAt()
    .setExpirationTime("2h") // Expiration time
    .sign(secret);
  return token;
}

// Verify a JWT
async function verifyJWT(token: string | Uint8Array) {
  const secret = new TextEncoder().encode(METABASE_SECRET_KEY);
  try {
    const { payload } = await jwtVerify(token, secret);
    console.log("Verified Payload:", payload);
  } catch (err) {
    console.error("Verification failed:", err);
  }
}

function ReportPage(this: any, props: any) {
  const [iframeUrl, setIframeUrl] = useState("");
  const [token, setToken] = useState("");
  const { student } = useContext(StudentContext);
  const { user } = useContext(UserContext);

  useEffect(() => {
    //const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZXNvdXJjZSI6eyJkYXNoYm9hcmQiOjQ3fSwicGFyYW1zIjp7fSwiZXhwIjoxNzI5MDYzMTAxLCJpYXQiOjE3MjkwNjI1MDB9.a1YmwOeaTm3J9QcJPihbotbqYx1eiMsTrMxjy2_5kvE'

    var payload = {}

    if (user.role === 'admin'){
      payload = {
        resource: { dashboard: 51 },
        params: {},
        exp: Math.round(Date.now() / 1000) + 10 * 60, // 10 minute expiration
      };
    }else{
      payload = {
        resource: { dashboard: 51 },
        params: {
          "text": [
            student.cmkl_email
          ]
        },
        exp: Math.round(Date.now() / 1000) + 10 * 60, // 10 minute expiration
      };
    }


    createJWT(payload).then((r) => {
      verifyJWT(r).then((l) => {
        setToken(r);
      });
    });
  }, []);
  useEffect(() => {
    if (token > "") {
      const url =
        METABASE_SITE_URL +
        "/embed/dashboard/" +
        token +
        "#bordered=false&titled=true";
      setIframeUrl(url); // Update the state with the iframe URL
    }
  }, [token]);

  const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: -1;
  `;
  const Iframe = styled.iframe`
    width: 100%;
    height: 85vh; /* Set height to 90vh */
    border: none; /* Remove border */
    overflow: none; /* Prevent overflow */
  `;
  return (
    <>
      {iframeUrl ? (
        <>
          <Iframe
            src={iframeUrl}
            allowFullScreen
            title="Metabase Dashboard"
          ></Iframe>
          <Overlay />
        </>
      ) : (
        <p>Loading dashboard...</p>
      )}
    </>
  );
}

export default ReportPage;
